import { Modal, Offcanvas, Popover, Tab, Tooltip } from "bootstrap";
import { removeAlerts } from "./utils/alert";

// Restrict input to only numeric
function onlyNumberKey(event: KeyboardEvent, decimal = false) {
  const ASCIICode = event.which ? event.which : event.keyCode;

  if (
    (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) ||
    (decimal && (ASCIICode === 110 || ASCIICode === 190))
  )
    return false;
  return true;
}

// Bootstrap popover setup
function activatePopovers() {
  document
    .querySelectorAll('[data-bs-toggle="popover"]')
    .forEach((popoverTriggerEl) => {
      Popover.getOrCreateInstance(popoverTriggerEl);
    });
}

// Bootstrap Tooltip setup
function activateTooltips() {
  document
    .querySelectorAll('[data-bs-toggle="tooltip"]')
    .forEach((tooltipTriggerEl) => {
      Tooltip.getOrCreateInstance(tooltipTriggerEl);
    });
}

function loadSpinners() {
  document
    .querySelectorAll<HTMLElement>(".spinner:not([data-listened])")
    .forEach((link) => {
      link.addEventListener("click", () => {
        link.innerHTML = `
        <i class='spinner-border spinner-border-sm' role='status'>
          <span class='visually-hidden'>Loading...</span>
        </i>
      `;
      });
      link.dataset["listened"] = "true";
    });
}

function attachTabListeners() {
  document.querySelectorAll('[role="tab"]').forEach((element) => {
    const tab = new Tab(element);
    element.addEventListener("show", () => tab.show());
  });
}

function attachOffcanvasListeners() {
  document.querySelectorAll(".offcanvas").forEach((element) => {
    const offcanvas = new Offcanvas(element);
    element.addEventListener("show", () => offcanvas.show());
    element.addEventListener("hide", () => offcanvas.hide());
  });
}

function attachModalListeners() {
  document.querySelectorAll(".modal").forEach((element) => {
    const modal = new Modal(element);
    element.addEventListener("show", () => modal.show());
    element.addEventListener("hide", () => modal.hide());
  });
}

function loadFormValidation() {
  document.querySelectorAll("form:not(.search-form)").forEach((form) => {
    const addValidation = () => {
      if (!form.classList.contains("was-validated")) {
        form.classList.add("was-validated");
      }
    };
    form.addEventListener("submit", addValidation);
    form
      .querySelector("input[type=submit]")
      ?.addEventListener("click", addValidation);
    form
      .querySelector("button[type=submit]")
      ?.addEventListener("click", addValidation);
  });
}

function addInputCount() {
  document.querySelectorAll(".count-character").forEach((element) => {
    const messageCount = element.querySelector(".message-char");

    element.previousElementSibling?.addEventListener("input", (e) => {
      const input = e.currentTarget;
      if (
        (input && input instanceof HTMLInputElement) ||
        input instanceof HTMLTextAreaElement
      ) {
        const text =
          input.maxLength === -1
            ? input.value.length.toString()
            : `${input.value.length}/${input.maxLength}`;
        if (messageCount) messageCount.textContent = text;
      }
    });
    element.previousElementSibling?.dispatchEvent(new Event("input"));
  });
}

document.addEventListener("DOMContentLoaded", () => {
  activatePopovers();
  activateTooltips();
  removeAlerts();
  addInputCount();
  loadSpinners();
  loadFormValidation();
  attachTabListeners();
  attachModalListeners();
  attachOffcanvasListeners();

  document.addEventListener("add-input-count", addInputCount);
  document.addEventListener("activate-popovers", activatePopovers);
  document.addEventListener("activate-tooltips", activateTooltips);

  document.querySelectorAll(".only-number").forEach((element) => {
    element.addEventListener("keypress", (e) =>
      onlyNumberKey(e as KeyboardEvent),
    );
  });
});

document.addEventListener("ajax:success", () => {
  loadSpinners();
});
