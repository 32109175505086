import Rails from "@rails/ujs";
import "bootstrap";

import "../channels/inbox-channel";
import "../src/chat-groups";
import getNotificationPermission from "../src/notifications/get-notification-permission";
import subscribe from "../src/notifications/subscribe";
import "../src/pwa";
import "../src/startup";
import "../src/typeable-select";
import "../src/utils/partytown";

document.addEventListener("DOMContentLoaded", () => {
  try {
    Rails.start();

    // Monkey patching the Rails.href method to support the data-url attribute
    // in the anchor tags
    Rails.href = (element) => {
      if (element.dataset.url) return element.dataset.url;

      if ("href" in element && typeof element.href === "string") {
        return element.href;
      }

      throw new Error("Element does not have a href attribute");
    };
  } catch (e) {}
});

window.addEventListener("load", async () => {
  // Call this method every time when the browser is reloaded
  setTimeZone();

  // Checking if the browser supports service workers (for PWA functionality)
  if (!("serviceWorker" in navigator)) return;

  try {
    const registration = await navigator.serviceWorker.register(
      "/serviceworker.js",
    );

    if (registration.installing) {
      console.info("Installing Service Worker ...");
    } else {
      const permission = getNotificationPermission();
      if (permission === "granted") {
        subscribe();
        console.info("Subscribed to push notifications");
      }

      const serviceWorker = registration.waiting
        ? registration.waiting
        : registration.active;

      console.info(`Service Worker State ${serviceWorker?.state}`);
    }
  } catch (registrationError) {
    console.error("Service worker registration failed: ", registrationError);
  }
});

// Set the timezone cookie if the existing timezone and current timezone
// is not the same using the INTL
const setTimeZone = () => {
  const existingTimezoneInCookie =
    document.cookie.match(/timezone=([^;]*)/)?.[1];

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (existingTimezoneInCookie === timezone) return;

  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); // set the cookie for 1 year
  document.cookie = `timezone=${timezone};expires=${date.toUTCString()};path=/`;
  document.dispatchEvent(new CustomEvent("timezone-updated"));
};
