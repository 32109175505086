import fetcher from "./fetcher";
import { debounce } from "./utils/debounce";

const selectedEvent = new CustomEvent("selected");
const populateOptionsEvent = new CustomEvent("populate-options");

const addTypeableListeners = () => {
  document
    .querySelectorAll<HTMLSelectElement>(
      ".typeable-select:not([data-listened])",
    )
    .forEach((element) => {
      const dataList = document.querySelector<HTMLDataListElement>(
        `#${element.id}-list`,
      );
      const elementHiddenInput = element.previousElementSibling;

      if (!dataList) {
        throw new Error(`No datalist found for typeable select #${element.id}`);
      }

      if (!(elementHiddenInput instanceof HTMLInputElement)) {
        throw new Error(
          `No hidden input found for typeable select #${element.id}`,
        );
      }

      // Filter the options of typeable select element and return the options as array
      const filterOptions = async () => {
        if (element.dataset.remoteUrl) {
          element.dispatchEvent(populateOptionsEvent);
          return;
        }

        dataList
          .querySelectorAll<HTMLDivElement>(".option")
          .forEach((option) => {
            if (
              !element.value ||
              option.dataset.text
                ?.toLowerCase()
                .includes(element.value.toLowerCase())
            ) {
              const text = element.value
                ? option.dataset.text?.replace(
                    RegExp(`(${element.value})`, "ig"),
                    `<span class='text-secondary text-decoration-underline'>$1</span>`,
                  )
                : option.dataset.text;

              option.innerHTML = `${
                Boolean(option.dataset.image) ? option.dataset.image : ""
              }${text}`;

              option.style.display = "";
              option.setAttribute("aria-hidden", "false");
              if (
                option.dataset.text?.toLowerCase() ===
                element.value.toLowerCase()
              ) {
                elementHiddenInput.value = option.dataset.value || "";
              }
            } else {
              option.style.display = "none";
              option.setAttribute("aria-hidden", "true");
            }
          });
      };

      element.addEventListener(
        "input",
        debounce(() => {
          elementHiddenInput.value = "";
          filterOptions();
        }, 500),
      );
      element.addEventListener("keydown", (event) => {
        if (
          event.code === "ArrowDown" ||
          event.key === "ArrowDown" ||
          (!event.shiftKey && (event.code === "Tab" || event.key === "Tab"))
        ) {
          event.preventDefault();
          const firstChild = dataList.querySelector<HTMLDivElement>(
            '[aria-hidden="false"]',
          );

          if (firstChild) {
            element.setAttribute("data-selecting", "true");
            firstChild.focus();
          } else {
            element.dispatchEvent(selectedEvent);
          }
        } else if (event.code === "Enter" || event.key === "Enter") {
          element.dispatchEvent(selectedEvent);
          event.preventDefault();
          event.stopPropagation();
        }
      });
      element.addEventListener("selected", () => {
        element.setAttribute("data-selecting", "true");
        element.blur();
      });
      element.addEventListener("blur", (event) => {
        if (element.value && !element.hasAttribute("data-selecting")) {
          // If focus switched to option
          // This is a fallback for the issue occuring that click event is not getting called
          // when the click happens on padding
          if (
            event.relatedTarget &&
            event.relatedTarget instanceof Element &&
            event.relatedTarget.classList.contains("option")
          ) {
          } else {
            element.dispatchEvent(selectedEvent);
            event.preventDefault();
            event.stopPropagation();
          }
        } else {
          element.removeAttribute("data-selecting");
        }
      });
      element.addEventListener("filter_options", filterOptions);
      element.addEventListener("add-option-listeners", addOptionListeners);
      element.addEventListener("populate-options", async () => {
        if (element.dataset.remoteUrl) {
          const requestUrl = `${element.dataset.remoteUrl}?value=${element.value}`;
          const res = await fetcher(requestUrl);
          const resJson = await res.json();
          element.dataset.options = JSON.stringify(resJson);
        }
        const parsedOptions = JSON.parse(element.dataset["options"] || "") as [
          string,
          string,
          string,
        ][];

        const newOptions: HTMLDivElement[] = [];
        parsedOptions.forEach(([text, value, image]) => {
          const option = document.createElement("div");
          option.tabIndex = -1;
          option.dataset["value"] = value;
          option.dataset["text"] = text;
          if (image) {
            option.dataset["image"] = image;
            option.innerHTML = image;
          } else {
            option.innerHTML = "";
          }
          (option.innerHTML +=
            (Boolean(element.value)
              ? option.dataset.text?.replace(
                  RegExp(`(${element.value})`, "ig"),
                  `<span class='text-secondary text-decoration-underline'>$1</span>`,
                )
              : option.dataset.text) || ""),
            option.setAttribute("class", "option");
          newOptions.push(option);
        });
        dataList.replaceChildren(...newOptions);
        addOptionListeners();
      });

      if (element.dataset.remoteUrl)
        element.dispatchEvent(populateOptionsEvent);

      element.dataset["listened"] = "true";
    });

  addOptionListeners();
};

const addOptionListeners = () => {
  document
    .querySelectorAll<HTMLElement>(
      ".typeable-select + datalist > .option:not([data-listened])",
    )
    .forEach((element) => {
      element.addEventListener("keydown", onTypeableOptionKeydown);
      element.addEventListener("click", (event) => {
        if (
          !(event.currentTarget instanceof HTMLElement) ||
          !(
            event.currentTarget.parentElement?.previousElementSibling instanceof
            HTMLInputElement
          )
        )
          return;

        selectOption(
          event.currentTarget.parentElement.previousElementSibling,
          event.currentTarget,
        );
      });
      element.dataset["listened"] = "true";
    });
};

const onTypeableOptionKeydown = (event: KeyboardEvent) => {
  const eventTarget = event.currentTarget as HTMLElement;
  const typeable = (eventTarget?.parentNode as HTMLElement)
    ?.previousElementSibling as HTMLInputElement;

  if (event.code === "Enter" || event.key === "Enter") {
    selectOption(typeable, eventTarget);
    event.preventDefault();
    event.stopPropagation();
  } else if (
    event.key === "ArrowDown" ||
    event.key === "ArrowDown" ||
    (!event.shiftKey && (event.code === "Tab" || event.key === "Tab"))
  ) {
    const nextSibling = getNextSibling(eventTarget, '[aria-hidden="false"]');
    if (nextSibling) nextSibling.focus();
    event.preventDefault();
  } else if (
    event.key === "ArrowUp" ||
    event.key === "ArrowUp" ||
    (event.shiftKey && (event.code === "Tab" || event.key === "Tab"))
  ) {
    const prevSibling = getPrevSibling(eventTarget, '[aria-hidden="false"]');
    if (prevSibling) prevSibling.focus();
    else typeable?.focus();

    event.preventDefault();
  } else if (![16, 17, 18].includes(event.keyCode)) typeable?.focus();
};

const selectOption = (typeable: HTMLInputElement, target: HTMLElement) => {
  typeable.value = target.dataset.text || "";
  if (typeable.previousElementSibling instanceof HTMLInputElement)
    typeable.previousElementSibling.value = target.dataset.value || "";
  typeable.dispatchEvent(selectedEvent);
  target.blur();
};

const getNextSibling = (element: HTMLElement, selector: string) => {
  // Get the next sibling element
  let sibling = element.nextElementSibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) return sibling as HTMLElement;
    sibling = sibling.nextElementSibling;
  }
};

const getPrevSibling = (element: HTMLElement, selector: string) => {
  // Get the next sibling element
  let sibling = element.previousElementSibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) return sibling as HTMLElement;
    sibling = sibling.previousElementSibling;
  }
};

document.addEventListener("listen-to-typeable", addTypeableListeners);

document.addEventListener("DOMContentLoaded", () => {
  document.dispatchEvent(new CustomEvent("listen-to-typeable"));
});
