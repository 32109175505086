import { noNullQuerySelector } from "./utils/dom";

// Remove user input and display
const removeUser = (userId: string) => {
  document
    .querySelectorAll(`[data-user='${userId}']`)
    .forEach((element) => element.remove());
  validateUser();
};

// Add user hidden input and display tag
const addUser = (
  userName = noNullQuerySelector<HTMLInputElement>("#users").value,
  userId = noNullQuerySelector<HTMLInputElement>("[name='user-hidden']").value,
) => {
  const users = noNullQuerySelector<HTMLInputElement>("#users");
  const usersContainer = noNullQuerySelector("#users-container");
  const hiddenFields = noNullQuerySelector("#group-users-hidden-fields");

  try {
    if (!document.querySelectorAll(`[data-user="${userId}"]`).length) {
      const removeUserButton = document.createElement("button");
      removeUserButton.id = `remove-${userId}`;
      removeUserButton.dataset.user = userId;
      removeUserButton.type = "button";
      removeUserButton.className = "status-tag primary";
      removeUserButton.addEventListener("click", () => removeUser(userId));

      const removeUserButtonSpan = document.createElement("span");
      removeUserButtonSpan.innerText = userName;
      const removeUserButtonIcon = document.createElement("i");
      removeUserButtonIcon.className = "bi bi-x";

      removeUserButton.appendChild(removeUserButtonSpan);
      removeUserButton.appendChild(removeUserButtonIcon);

      usersContainer.appendChild(removeUserButton);

      const userHiddenInput = document.createElement("input");
      userHiddenInput.type = "hidden";
      userHiddenInput.name = "chat_group[user_ids][]";
      userHiddenInput.value = userId;
      userHiddenInput.dataset.user = userId;

      hiddenFields.appendChild(userHiddenInput);
    } else {
      throw `You have already selected user "${userName}". Please select some other user.`;
    }
  } catch (e) {
    console.error(e);
  } finally {
    users.value = "";
    users.dispatchEvent(new Event("input"));
  }

  validateUser();
};

// validation on user button tags
const validateUser = () => {
  const userInput = document.getElementById("users") as HTMLInputElement;
  if (!userInput || !userInput.dataset.minimum) return false;

  if (
    document.querySelectorAll("[data-user]").length >
    parseInt(userInput.dataset.minimum)
  ) {
    userInput.setCustomValidity("");
    return true;
  } else {
    userInput.setCustomValidity("You must select students...!");
    return false;
  }
};

export const addUserListeners = () => {
  const userInput = noNullQuerySelector("#users");
  userInput.addEventListener("selected", () => addUser());
  userInput.addEventListener("focus", () => {
    userInput.setAttribute("placeholder", "Start typing user names...");
  });
  userInput.addEventListener("blur", () => {
    userInput.setAttribute("placeholder", "You can select multiple students");
  });
};

document.addEventListener("listen-to-user-input", addUserListeners);
document.addEventListener("validate-user-input", validateUser);
