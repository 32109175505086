import { Modal } from "bootstrap";
import checkNotificationPromise from "../notifications/check-notification-promise";
import subscribe from "../notifications/subscribe";
import {
  getPWADisplayMode,
  isIos,
  isIosPwaFlagSet,
  markAsInstalled,
} from "./utils";

let modalElement: HTMLDivElement | null = null;

const showNotificationModal = () => {
  if (!modalElement) {
    modalElement = document.createElement("div");
    modalElement.classList.add("modal", "fade");
    modalElement.setAttribute("id", "notification-install-modal");
    modalElement.setAttribute("aria-modal", "true");
    modalElement.setAttribute("tabindex", "-1");
    modalElement.setAttribute("role", "dialog");
    modalElement.setAttribute("aria-labelledby", "notification-install-label");
    modalElement.innerHTML = `
      <div class='modal-dialog modal-dialog-centered'>
        <div class='modal-content'>
          <div class='modal-header'>
            <h5 class='modal-title' id="notification-install-label">
              Enable Notifications
            </h5>
            <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'>
            </button>
          </div>
          <div class='modal-body'>
            <p class='text-dark'>
              Click YES to stay updated.
              TutorBees notifications are for class timings, messages, and offers
            </p>
          </div>
          <div class='modal-footer'>
            <button
              type='button'
              class='btn-outline-primary p-3 flex-fill'
              data-bs-dismiss='modal'
            >
              No
            </button>
            <button
              type='button'
              class='btn-primary p-3 flex-fill push-notification-toggle'
            >
              YES
            </button>
          </div>
        </div>
      </div>
    `;
  }

  document.body.appendChild(modalElement);
  const modal = new Modal(modalElement);
  modal.show();

  document
    .querySelector(".push-notification-toggle")
    ?.addEventListener("click", () => {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(() => {
          modal.hide();
          if (Notification.permission === "granted") subscribe();
        });
      } else {
        Notification.requestPermission(() => {
          modal.hide();
          if (Notification.permission === "granted") subscribe();
        });
      }
    });
};

document.addEventListener("DOMContentLoaded", () => {
  let deferredPrompt: BeforeInstallPromptEvent | null = null;

  window.addEventListener("beforeinstallprompt", (e: Event) => {
    // Stash the event so it can be triggered later.
    deferredPrompt = e as BeforeInstallPromptEvent;

    document.querySelectorAll(".pwa-install-btn").forEach((btn) => {
      btn.classList.remove("d-none");
      btn.addEventListener("click", async () => {
        if (deferredPrompt === null) return;

        try {
          await deferredPrompt.prompt();
        } catch (e) {
          console.error(e);
        } finally {
          // Hide the opened prompt
          document.querySelector("#pwa-install-modal")?.remove();
          document.querySelector(".modal-backdrop")?.remove();
          const body = document.querySelector<HTMLBodyElement>("body");
          body?.removeAttribute("style");
          body?.classList.remove("modal-open");
        }
      });
    });

    if (getPWADisplayMode() === "browser") {
      document
        .querySelector<HTMLDivElement>("pwa-install-modal")
        ?.dispatchEvent(new Event("show"));
    }
  });

  window.addEventListener("appinstalled", () => {
    markAsInstalled();
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt = null;
  });

  if (getPWADisplayMode() === "standalone") {
    if (isIos() && !isIosPwaFlagSet()) {
      markAsInstalled();
    }

    if (Notification?.permission === "default") {
      showNotificationModal();
    }
  }

  // Track when the user switches to PWA
  window
    .matchMedia("(display-mode: standalone)")
    .addEventListener("change", (evt) => {
      if (evt.matches) {
        // Hide the opened prompt
        document.querySelector("#pwa-install-modal")?.remove();
        document.querySelector(".modal-backdrop")?.remove();
        const body = document.querySelector<HTMLBodyElement>("body");
        body?.removeAttribute("style");
        body?.classList.remove("modal-open");

        showNotificationModal();
      }
    });
});
