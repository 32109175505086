import { addMessageNotification } from "../src/message/add-message-notification.ts";
import observeUnread from "../src/message/observers/unread.ts";
import { getAttachment } from "../src/message/renderers/attachment.ts";
import { renderQuotedMessageContent } from "../src/message/reply.ts";
import { updateLatestMessage } from "../src/message/update-latest-message.ts";
import { animateHide } from "../src/utils/animate.ts";
import { noNullQuerySelector } from "../src/utils/dom.ts";
import { MissingDOMError } from "../src/utils/error.ts";
import consumer from "./consumer.ts";
import type { Message, QuotedMessage } from "./inbox-channel.types.ts";

const getAvatarElement = (message: Message): HTMLElement => {
  if (message.sender.image_url === "") {
    const avatar = document.createElement("span");
    avatar.className =
      "avatar rounded-circle text-white d-inline-flex justify-content-center align-items-center";
    avatar.style.width = "32px";
    avatar.style.height = "32px";
    avatar.style.fontSize = "10px";
    avatar.style.backgroundColor = message.sender.bg_color;
    avatar.style.textTransform = "uppercase";
    avatar.textContent = message.sender.name
      .split(" ")
      .map((n) => n[0])
      .join("");
    return avatar;
  } else {
    const avatar = document.createElement("img");
    avatar.src = message.sender.image_url;
    avatar.alt = "";
    avatar.className = "avatar rounded-circle";
    avatar.width = 32;
    avatar.height = 32;
    avatar.loading = "lazy";
    return avatar;
  }
};

const getQuotedMessageElement = (quotedMessage: QuotedMessage) => {
  const quotedMessageElement = document.createElement("div");
  quotedMessageElement.className =
    "d-flex flex-column flex-fill gap-1 fw-medium text-secondary-emphasis border-start rounded border-5 border-secondary ps-2 pe-5 py-1 mb-2 bg-secondary-subtle fs-14";

  const quotedMessageSender = document.createElement("span");
  quotedMessageSender.className = "fw-bold";
  quotedMessageSender.innerHTML = quotedMessage.sender_name;

  const quotedMessageContent = document.createElement("div");
  quotedMessageContent.className = "fw-medium";
  renderQuotedMessageContent(
    quotedMessageContent,
    quotedMessage.text,
    quotedMessage.attachments,
  );

  quotedMessageElement.appendChild(quotedMessageSender);
  quotedMessageElement.appendChild(quotedMessageContent);

  return quotedMessageElement;
};

const getDropdownMenu = (message: Message) => {
  const dropdownMenu = document.createElement("div");
  dropdownMenu.className = "position-absolute dropup";
  dropdownMenu.style.right = "7px";
  dropdownMenu.style.zIndex = "2";
  dropdownMenu.style.top = "7px";

  const dropdownButton = document.createElement("i");
  dropdownButton.className =
    "bi bi-three-dots-vertical text-secondary-emphasis p-1 rounded-circle d-flex justify-content-center align-items-center";
  dropdownButton.style.height = "1.5rem";
  dropdownButton.style.width = "1.5rem";
  dropdownButton.setAttribute("role", "button");
  dropdownButton.setAttribute("data-bs-toggle", "dropdown");
  dropdownButton.setAttribute("aria-expanded", "false");
  dropdownButton.setAttribute("aria-label", "Message Options");
  dropdownMenu.appendChild(dropdownButton);

  const dropdownList = document.createElement("ul");
  dropdownList.className =
    "dropdown-menu dropdown-menu-end rounded-3 shadow border-0 ms-3";

  const replyItem = document.createElement("li");
  const replyLink = document.createElement("a");
  replyLink.className = "dropdown-item text-primary pb-0 reply-message-link";
  replyLink.setAttribute("role", "button");
  replyLink.setAttribute("data-message-id", message.id.toString());
  replyLink.setAttribute("data-message-text", message.text);
  replyLink.setAttribute("data-message-sender", message.sender.name);
  replyLink.setAttribute(
    "data-message-attachment",
    JSON.stringify(message.attachments[0] || null),
  );
  replyLink.innerHTML =
    "<i class='bi bi-reply-fill me-2' aria-hidden='true'></i>Reply";
  replyItem.appendChild(replyLink);

  dropdownList.appendChild(replyItem);
  dropdownMenu.appendChild(dropdownList);

  return dropdownMenu;
};

consumer.subscriptions.create("InboxChannel", {
  received: (message: Message) => {
    console.info(`Received broadcast from message: ${message.id}`);
    const isSender =
      message.sender.id ===
      parseInt(document.body.dataset.currentUserId as string);
    // Do nothing if message is already added
    if (document.getElementById(`message-${message.id}`)) return;

    try {
      updateLatestMessage(message, isSender);
    } catch (e) {
      if (e instanceof MissingDOMError) {
        console.info("Skipping rendering message because inbox is not shown");
      } else throw e;
    }

    if (isSender) {
      console.info(
        `Skipping rendering message ${message.id} as it's sent by the current user`,
      );
      return;
    }

    addMessageNotification(message);

    const chatContainer = document.querySelector<HTMLElement>(
      message.chat_group_id
        ? `.message-history[data-chat-group-id="${message.chat_group_id}"]`
        : `.message-history[data-other-user-id="${message.sender.id}"]`,
    );

    // ? The inbox is not focused so no need to render the message.
    if (!chatContainer) {
      console.info(
        `Inbox not focused, skipping rendering message ${message.id}`,
      );
      return;
    }

    const messageElement = document.createElement("div");
    messageElement.id = `message-${message.id}`;
    messageElement.className = "mt-2 position-relative fw-medium d-flex gap-2";
    messageElement.setAttribute(
      "data-opposite-id",
      message.sender.id.toString(),
    );
    if (message.chat_group_id) {
      messageElement.setAttribute(
        "data-chat-group-id",
        message.chat_group_id.toString(),
      );
    }

    const avatar = getAvatarElement(message);
    messageElement.appendChild(avatar);

    const messageBody = document.createElement("div");
    messageBody.appendChild(getDropdownMenu(message));
    messageBody.className = `received-message px-2 pb-1 unread ${
      message.attachments.length > 0 ? "with-attached" : ""
    }`;
    if (message.quoted_message) {
      messageBody.classList.add("pt-2");
      messageBody.appendChild(getQuotedMessageElement(message.quoted_message));
    } else {
      messageBody.classList.add("pt-3");
    }
    messageBody.setAttribute("data-id", message.id.toString());
    messageBody.setAttribute(
      "data-chat-id",
      message.chat_group_id?.toString() || message.sender.id.toString(),
    );
    messageBody.setAttribute(
      "data-type",
      message.chat_group_id ? "group" : "user",
    );

    message.attachments.forEach((attachment) => {
      console.info(`Rendering attachment ${attachment.id}`);
      const attachmentElement = getAttachment(attachment);
      messageBody.appendChild(attachmentElement);
    });

    const messageContent = document.createElement("div");
    messageContent.className = "message-content";
    messageContent.innerHTML = message.text;

    const messageContentSpan = document.createElement("span");
    messageContentSpan.style.fontSize = "0.7rem";
    messageContentSpan.style.opacity = "75%";
    messageContentSpan.innerHTML = `${message.sender.name}, <time datetime="${message.created_at}" data-local="time" data-format="%I:%M %p" title="${message.created_at}">${message.created_at}</time>`;

    messageContent.appendChild(messageContentSpan);
    messageBody.appendChild(messageContent);
    messageElement.appendChild(messageBody);

    const typingIndicator = noNullQuerySelector(
      ".typing-indicator",
      chatContainer,
    );
    chatContainer.insertBefore(
      messageElement,
      typingIndicator.nextElementSibling,
    );

    // hide typing indicator if it is being shown
    chatContainer
      .querySelectorAll<HTMLElement>(".typing-indicator")
      .forEach((element) => {
        animateHide(element);
      });

    setTimeout(() => {
      observeUnread();
    }, 1000);
    document.dispatchEvent(new CustomEvent("reload-image-listeners"));
    document.dispatchEvent(new CustomEvent("add-reply-listeners-on-message"));
  },
});
