// Increment unread message counters & update latest message showing text

import type { Message } from "../../channels/inbox-channel.types";

// Use userId input to only update the specific user's counters
export const addMessageNotification = (message: Message) => {
  document
    .querySelectorAll<HTMLElement>(
      `.unread-message-count[data-${
        message.chat_group_id ? "group" : "user"
      }-id="${
        message.chat_group_id || message.sender.id
      }"], .unread-message-count[data-type="header"]`,
    )
    .forEach((element) => {
      const newCount = parseInt(element.getAttribute("data-count") || "0") + 1;
      element.setAttribute("data-count", newCount.toString());
      // Show the indicator if previously it was hidden
      if (newCount > 9) {
        element.innerText = "9+";
      } else {
        // If just increased from 0
        if (newCount === 1) {
          element.classList.remove("d-none");
        }
        element.innerText = newCount.toString();
      }
    });
};
