import fetcher from "../fetcher";

// Detects if device is on iOS
export const isIos = () => {
  return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
};

export const getPWADisplayMode = () => {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  if (document.referrer.startsWith("android-app://")) {
    return "twa";
  } else if ("standalone" in navigator || isStandalone) {
    return "standalone";
  }
  return "browser";
};

// Check if the local flag for PWA installated is set
export const isIosPwaFlagSet = () =>
  localStorage.getItem("isPwaInstalled") !== null;

// Send the post request to mark pwa installed for user
export const markAsInstalled = async () => {
  try {
    const res = await fetcher(`/set-pwa-installed`, { method: "POST" });
    if (!res.ok) throw Error("Failed to set PWA as installed");

    // Set the local flag for PWA installated
    localStorage.setItem("isPwaInstalled", "true");
  } catch (e) {
    console.error(e);
  }
};
